.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ab001c;
}
.button:active {
  background-color: #780014;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #c40020;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
i {
  font-style: normal;
}
strong {
  font-weight: 500;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 15px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.46666667;
}
.unit caption {
  display: none;
}
.flag {
  background: #c40020;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
  position: relative;
}
@media (max-width: 1023px) {
  .unit {
    margin-top: 14px;
  }
}
@media (max-width: 639px) {
  .unit {
    margin-top: 14px;
  }
}
@media (max-width: 1023px) {
  .unit {
    margin-bottom: 14px;
  }
}
@media (max-width: 639px) {
  .unit {
    margin-bottom: 14px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  position: relative;
}
@media (max-width: 1023px) {
  .part {
    margin-top: 6px;
  }
}
@media (max-width: 639px) {
  .part {
    margin-top: 6px;
  }
}
@media (max-width: 1023px) {
  .part {
    margin-bottom: 6px;
  }
}
@media (max-width: 639px) {
  .part {
    margin-bottom: 6px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dcdcd8;
}
.content--1 .area .seam .line hr,
.content--1 .area .flat .line hr,
.content--2 .area .seam .line hr,
.content--2 .area .flat .line hr {
  border-color: rgba(255, 255, 255, 0.7);
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important;
  padding-bottom: 23.4375%;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #c40020;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #c40020;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 639px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 639px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 639px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #c40020;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #c40020;
  font-size: 16px;
  font-weight: 500;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #ab001c;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #780014;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 639px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 639px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 639px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 639px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 639px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 639px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 639px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch form {
  width: 100%;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Ubuntu', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 639px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 639px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 640px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 640px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 9px;
    padding-bottom: 9px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 640px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 9px;
    padding-bottom: 9px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 22px;
  vertical-align: top;
}
@media (min-width: 640px) {
  .unit.form div.tick.tile input {
    top: 9px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 1.46666667;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 640px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-top: 6px;
  }
}
@media (max-width: 639px) {
  .unit.form input.submit {
    margin-top: 6px;
  }
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-bottom: 6px;
  }
}
@media (max-width: 639px) {
  .unit.form input.submit {
    margin-bottom: 6px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff;
  background-color: #ab001c;
}
.unit.form input.submit:active {
  background-color: #780014;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-top: 6px;
  }
}
@media (max-width: 639px) {
  .unit.form div.cb-form-sent {
    margin-top: 6px;
  }
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 6px;
  }
}
@media (max-width: 639px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 6px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #ab001c;
}
.two-step-verification-container a:active {
  background-color: #780014;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: 500;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: 500;
}
@media (max-width: 639px) {
  .part--table .table {
    font-size: 2.34741784vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  position: absolute;
  right: 0;
  top: 0;
  height: 96px;
  z-index: 2;
}
#home:after {
  content: attr(title);
  position: absolute;
  right: 24px;
  top: 100%;
  margin-top: 20px;
  z-index: 2;
  color: #000;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.28571429;
  font-weight: bold;
  display: none;
}
.cb-layout1 #home {
  position: relative;
  float: right;
  margin-bottom: 64px;
}
@media (max-width: 639px) {
  #home {
    height: 86px;
  }
  #home:after {
    width: 200px;
    white-space: initial;
    text-align: right;
    margin-top: 10px;
    right: 14px;
  }
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 1.46666667;
  color: #000;
  overflow: hidden;
  hyphens: none;
  -webkit-font-smoothing: antialiased;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 96.09375%;
  width: 1200px;
  width: 984px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
@media (max-width: 1023px) {
  .desk {
    width: 700px;
  }
  .section--header .desk {
    width: 100%;
    max-width: calc(100% - 68px);
  }
}
@media (max-width: 639px) {
  .desk {
    width: 100%;
    max-width: calc(100% - 40px);
  }
  .section--header .desk {
    max-width: calc(100% - 40px);
  }
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.cb-layout1 .section--header {
  border-bottom: 1px solid #dcdcd8;
}
.section--multimood {
  height: 33.203125vw;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.cb-layout1 .section--multimood {
  display: none;
}
@media (max-width: 1023px) {
  .section--multimood {
    height: 37.5vw;
    margin-top: 84px;
  }
}
@media (max-width: 639px) {
  .section--multimood {
    margin-top: 64px;
  }
}
.section--navigation {
  z-index: 2;
  background-color: #fff;
  border-bottom: 1px solid transparent;
  transition: all 0.4s;
}
.section--navigation.cb-navi-fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  border-color: #dcdcd8;
}
.section--navigation.cb-navi-sticky-clone {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .section--navigation {
    display: none;
  }
}
.section--footlinks {
  margin-top: 60px;
}
@media (max-width: 1023px) {
  .section--footlinks {
    margin-top: 60px;
  }
}
@media (max-width: 639px) {
  .section--footlinks {
    margin-top: 60px;
  }
}
.headercontent {
  float: left;
  width: 100%;
}
.titlecontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
@media (max-width: 639px) {
  .titlecontent {
    margin-top: 30px;
  }
}
.titlecontent__section {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pfad {
  float: left;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 26px;
}
.pfad:empty,
.cb-layout3 .pfad {
  display: none;
}
.pfad a {
  float: left;
  text-decoration: none;
  padding-right: 16px;
  margin-right: 8px;
  background-size: 8px 12px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-red.svg);
}
.pfad a:last-child {
  padding-right: 0;
  margin-right: 0;
  background: none;
}
.titlecontent .service_newsletter {
  float: left;
  flex-shrink: 0;
  white-space: nowrap;
  margin-left: 40px;
  font-weight: 500;
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
  text-decoration-color: #c40020;
  text-underline-offset: 2px;
  padding-right: 46px;
  padding-bottom: 4px;
  background-size: auto 20px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-mail-black-2021-11-11.svg);
}
.titlecontent .service_newsletter:hover,
.titlecontent .service_newsletter:focus {
  color: #c40020;
  background-image: url(/images/social-mail-red-2021-11-11.svg);
}
@media (max-width: 1023px) {
  .titlecontent .service_newsletter {
    display: none;
  }
}
.content {
  float: left;
  width: 100%;
}
.content:empty {
  display: none;
}
.navicontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#quicklinks {
  float: left;
  margin-left: 20px;
  flex-shrink: 0;
}
#quicklinks .meta {
  float: left;
  margin-right: 12px;
  width: 26px;
  height: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-search-black-2021-11-11.svg);
}
#quicklinks .meta:last-child {
  margin-right: 0;
}
#quicklinks .meta:hover,
#quicklinks .meta:focus {
  background-image: url(/images/social-search-red-2021-11-11.svg);
}
#quicklinks .meta.service_linkedin {
  width: 22px;
  background-image: url(/images/social-linkedin-black.svg);
}
#quicklinks .meta.service_linkedin:hover,
#quicklinks .meta.service_linkedin:focus {
  background-image: url(/images/social-linkedin-red.svg);
}
#quicklinks .meta.service_newsletter {
  width: 30px;
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s;
  pointer-events: none;
  background-image: url(/images/social-mail-black-2021-11-11.svg);
}
#quicklinks .meta.service_newsletter:hover,
#quicklinks .meta.service_newsletter:focus {
  background-image: url(/images/social-mail-red-2021-11-11.svg);
}
.cb-navi-fixed #quicklinks .meta.service_newsletter {
  transform: scale(1);
  opacity: 1;
  pointer-events: initial;
}
#footlinks {
  float: left;
}
#footlinks .meta {
  float: left;
  font-weight: 500;
  color: #000;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
  text-decoration-color: #c40020;
  text-underline-offset: 2px;
  padding-top: 28px;
  background-size: auto 20px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/social-linkedin-black.svg);
  margin-right: 160px;
}
#footlinks .meta:last-child {
  margin-right: 0;
}
#footlinks .meta:hover,
#footlinks .meta:focus {
  color: #c40020;
  background-image: url(/images/social-linkedin-red.svg);
}
#footlinks .meta.service_newsletter {
  background-image: url(/images/social-mail-black-2021-11-11.svg);
}
#footlinks .meta.service_newsletter:hover,
#footlinks .meta.service_newsletter:focus {
  background-image: url(/images/social-mail-red-2021-11-11.svg);
}
@media (max-width: 1023px) {
  #footlinks .meta {
    margin-right: 80px;
  }
}
@media (max-width: 639px) {
  #footlinks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  #footlinks .meta {
    margin-right: 0;
    margin-top: 30px;
  }
  #footlinks .meta:first-child {
    margin-top: 0;
  }
}
.footcompany {
  float: left;
  width: 100%;
  font-weight: bold;
  margin-top: 40px;
}
.toplink {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding-top: 28px;
  background-size: 12px 18px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/toplink-black.svg);
  cursor: pointer;
}
.toplink:hover,
.toplink:focus {
  color: #c40020;
  background-image: url(/images/toplink-red.svg);
}
.footcontent {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 10px;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footcontent:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100%;
  background-color: #dcdcd8;
  box-sizing: border-box;
  border-right: 10px solid #c40020;
}
@media (max-width: 639px) {
  .footcontent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.vcard {
  float: left;
  position: relative;
  z-index: 2;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.73333333;
}
.vcard a {
  text-decoration: none;
}
@media (max-width: 639px) {
  .vcard {
    font-size: 13px;
    line-height: 1.84615385;
  }
}
.foothome {
  float: left;
  margin-right: 24px;
  position: relative;
  z-index: 2;
  height: 60px;
}
@media (max-width: 639px) {
  .foothome {
    margin-right: 0;
    height: 40px;
    margin-top: 20px;
  }
}
#cmsbox {
  float: left;
  width: 100%;
  border-left: 10px solid transparent;
  border-right: 10px solid #c40020;
  box-sizing: border-box;
  text-align: center;
  padding: 6px 0;
}
#cmsbox .meta {
  text-decoration: none;
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100vw - 80px);
  width: 400px;
  z-index: 2100;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 639px) {
  #cb-cookie-warning {
    left: 7vw;
    bottom: 7vw;
    max-width: 86vw;
    font-size: 15px;
    line-height: 1.46666667;
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
}
@media (max-width: 639px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
}
.cb-cookie-warning__button {
  float: left;
  width: 50%;
  cursor: pointer;
}
#cb-cookie-warning__button--decline:hover {
  background-color: #f5f5f5;
}
#cb-cookie-warning__button--accept {
  background-color: #c40020;
  line-height: 1.46666667;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  color: #fff;
  background-color: #ab001c;
}
#cb-cookie-warning__button--accept:active {
  background-color: #780014;
}
a {
  color: inherit;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #c40020;
  text-decoration: none;
}
.part.text strong {
  font-weight: inherit;
}
.part.text .norm strong {
  font-weight: 500;
}
.part.text .norm i {
  font-style: italic;
}
h1 {
  font-weight: normal;
  font-size: 21px;
  line-height: 1.23809524;
  font-weight: bold;
  text-transform: uppercase;
}
h1 strong {
  font-size: 42px;
  line-height: 1.19047619;
  font-weight: 500;
}
h2 {
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.01em;
  position: relative;
  padding-top: 10px;
}
h2 strong {
  font-weight: 700;
}
h2:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 5px;
  background-color: #c40020;
}
.c2 h2:before {
  background-color: #839caf;
}
.c3 h2:before {
  background-color: #847f73;
}
.c4 h2:before {
  background-color: #710030;
}
.content--3 .area .pure h2:before {
  background-color: #c40020 !important;
}
.content--3 .area .seam h2:before {
  background-color: #839caf !important;
}
.content--3 .area .flat h2:before {
  background-color: #847f73 !important;
}
.content--3 .area .edge h2:before {
  background-color: #710030 !important;
}
h3 {
  font-size: 24px;
  line-height: 1.16666667;
  font-weight: 500;
  text-transform: uppercase;
}
h3 strong {
  font-weight: 700;
}
h4 {
  font-size: 21px;
  line-height: 1.33333333;
  font-weight: 500;
}
h4 strong {
  font-weight: 700;
}
h5 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
}
h5 strong {
  font-weight: 700;
}
.content--1 .area,
.content--3 .area {
  display: flex;
  flex-wrap: wrap;
}
.content--1 .area .pure:not(.fold),
.content--3 .area .unit:not(.fold) {
  border-bottom: 1px solid #dcdcd8;
  margin-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .content--1 .area .pure:not(.fold),
  .content--3 .area .unit:not(.fold) {
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .content--1 .area .pure:not(.fold),
  .content--3 .area .unit:not(.fold) {
    margin-bottom: 20px;
  }
}
.content--1 .area .seam:not(.fold),
.content--1 .area .flat:not(.fold),
.content--2 .area .seam:not(.fold),
.content--2 .area .flat:not(.fold) {
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 14px;
  padding-right: 14px;
}
@media (max-width: 1023px) {
  .content--1 .area .seam:not(.fold),
  .content--1 .area .flat:not(.fold),
  .content--2 .area .seam:not(.fold),
  .content--2 .area .flat:not(.fold) {
    margin-top: 20px;
  }
}
@media (max-width: 639px) {
  .content--1 .area .seam:not(.fold),
  .content--1 .area .flat:not(.fold),
  .content--2 .area .seam:not(.fold),
  .content--2 .area .flat:not(.fold) {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  .content--1 .area .seam:not(.fold),
  .content--1 .area .flat:not(.fold),
  .content--2 .area .seam:not(.fold),
  .content--2 .area .flat:not(.fold) {
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .content--1 .area .seam:not(.fold),
  .content--1 .area .flat:not(.fold),
  .content--2 .area .seam:not(.fold),
  .content--2 .area .flat:not(.fold) {
    margin-bottom: 20px;
  }
}
.content--1 .area .seam:not(.fold) {
  background-color: #efefea;
}
.content--2 .area .seam:not(.fold) {
  background-color: #dcdcd8;
}
.content--1 .area .flat:not(.fold),
.content--2 .area .flat:not(.fold) {
  background-color: #d9e5e9;
}
.content--1 .area .edge:not(.fold),
.content--2 .area .edge:not(.fold) {
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}
.content--2 .area .pure:not(.fold) {
  box-sizing: border-box;
  padding-left: 14px;
  padding-right: 14px;
}
.unit.fold {
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcd8;
}
@media (max-width: 1023px) {
  .unit.fold {
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .unit.fold {
    margin-bottom: 20px;
  }
}
.unit.fold .fold-toggle {
  text-decoration: none;
  color: #000;
}
.unit.fold .fold-toggle:after {
  content: '';
  display: inline-block;
  width: 12px;
  height: 8px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-bottom-red.svg);
  margin-left: 5px;
  transform: translateY(-1px);
}
.unit.fold .fold-toggle:hover {
  color: #c40020;
}
.unit.fold .fold-toggle.fold-toggle--open:after {
  background-image: url(/images/chevron-top-red.svg);
}
.unit.fold .foot {
  display: none;
}
.content--1 .area .fold.pure,
.content--3 .area .fold.pure,
.content--1 .area .fold.seam,
.content--3 .area .fold.seam {
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
}
.content--1 .area .fold.flat,
.content--3 .area .fold.flat,
.content--1 .area .fold.edge,
.content--3 .area .fold.edge {
  border-top: 1px solid #dcdcd8;
  background-color: #efefea;
  padding-left: 14px;
  padding-right: 14px;
  border-color: #b9b9b0;
  margin-top: 20px;
}
@media (max-width: 1023px) {
  .content--1 .area .fold.flat,
  .content--3 .area .fold.flat,
  .content--1 .area .fold.edge,
  .content--3 .area .fold.edge {
    margin-top: 20px;
  }
}
@media (max-width: 639px) {
  .content--1 .area .fold.flat,
  .content--3 .area .fold.flat,
  .content--1 .area .fold.edge,
  .content--3 .area .fold.edge {
    margin-top: 20px;
  }
}
.content--1 .area .fold.seam,
.content--3 .area .fold.seam,
.content--1 .area .fold.edge,
.content--3 .area .fold.edge {
  margin-bottom: 61px;
}
.content--1 .area .fold.seam:last-child,
.content--3 .area .fold.seam:last-child,
.content--1 .area .fold.edge:last-child,
.content--3 .area .fold.edge:last-child {
  margin-bottom: 20px;
}
@media (max-width: 1023px) {
  .content--1 .area .fold.seam:last-child,
  .content--3 .area .fold.seam:last-child,
  .content--1 .area .fold.edge:last-child,
  .content--3 .area .fold.edge:last-child {
    margin-bottom: 20px;
  }
}
@media (max-width: 639px) {
  .content--1 .area .fold.seam:last-child,
  .content--3 .area .fold.seam:last-child,
  .content--1 .area .fold.edge:last-child,
  .content--3 .area .fold.edge:last-child {
    margin-bottom: 20px;
  }
}
.content--1 .area .unit:not(.fold) + .unit.fold.pure div.less,
.content--3 .area .unit:not(.fold) + .unit.fold.pure div.less {
  padding-top: 0;
}
div.link a.open {
  float: left;
  font-weight: 500;
  text-decoration: none;
}
div.link a.open:after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-red.svg);
  transform: translateY(1px);
  margin-left: 5px;
}
div.link.site a.open:after {
  content: '';
  width: 10px;
  height: 10px;
  background-image: url(/images/icon-extern-red.svg);
  transform: translateY(0);
}
div.load a.load {
  float: left;
  padding-right: 18px;
  background-size: 13px 12px;
  background-position: 100% 4px;
  background-repeat: no-repeat;
  background-image: url(/images/icon-load-red.svg);
  font-weight: 500;
  text-decoration: none;
}
@media (max-width: 1023px) {
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: fixed;
  top: 20px;
  left: 34px;
  z-index: 2001;
  width: 36px;
  height: 24px;
  cursor: pointer;
}
@media (max-width: 639px) {
  .togglenavigation {
    left: 20px;
  }
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 4px;
  background-color: #c40020;
  border-radius: 4px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1), opacity 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateX(-20px);
}
body.cb-toggle-target-active .tline--2 {
  transform: translateY(-50%) rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: translateY(-50%) rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateX(20px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  width: 100%;
  max-width: 360px;
  background-color: #fff;
  overflow-y: scroll;
  transform: translateX(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateX(0);
  box-shadow: 0 0 10px rgba(120, 120, 120, 0.4);
}
@media (max-width: 639px) {
  .mobile-navigation {
    max-width: 300px;
  }
}
.mobile-navi-animation {
  float: left;
  width: 100%;
}
#mobservices {
  float: left;
  margin-left: 34px;
  margin-top: 50px;
  margin-bottom: 50px;
}
#mobservices .meta {
  float: left;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 31px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-mail-black-2021-11-11.svg);
  margin-right: 20px;
}
#mobservices .meta:last-child {
  margin-right: 0;
}
#mobservices .meta.find {
  width: 26px;
  background-image: url(/images/social-search-black-2021-11-11.svg);
}
#mobservices .meta.service_linkedin {
  width: 22px;
  background-image: url(/images/social-linkedin-black.svg);
}
@media (max-width: 639px) {
  #mobservices {
    margin-left: 20px;
  }
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #c40020;
}
.mobile-navigation div.sub1 {
  float: right;
  width: auto;
  display: flex;
  margin-right: 34px;
  margin-top: 34px;
}
@media (max-width: 639px) {
  .mobile-navigation div.sub1 {
    margin-top: 24px;
  }
}
.mobile-navigation div.sub1 > .item {
  width: auto;
  margin-right: 20px;
}
.mobile-navigation div.sub1 > .item.exit {
  margin-right: 0;
}
.mobile-navigation div.sub1 > .item > .menu {
  text-decoration: none;
  font-size: 12px;
  line-height: 1.33333333;
  color: #000;
}
.mobile-navigation div.sub1 > .item > .menu:hover,
.mobile-navigation div.sub1 > .item > .menu:focus {
  color: #c40020;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  font-weight: 500;
  color: #000;
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  padding: 0 34px;
  margin-top: 60px;
}
@media (max-width: 639px) {
  .mobile-navigation div.sub2 {
    margin-top: 50px;
    padding: 0 20px;
  }
}
.mobile-navigation div.sub2 > .item {
  margin-top: 20px;
}
.mobile-navigation div.sub2 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub2 > .item.c2 > .menu {
  border-color: #839caf;
}
.mobile-navigation div.sub2 > .item.c3 > .menu {
  border-color: #847f73;
}
.mobile-navigation div.sub2 > .item.c4 > .menu {
  border-color: #710030;
}
.mobile-navigation div.sub2 > .item > .menu {
  border-left: 10px solid #c40020;
  padding: 7px 36px 7px 10px;
  font-size: 15px;
  line-height: 1.2;
  min-height: 50px;
  text-decoration: none;
  box-sizing: border-box;
}
.mobile-navigation div.sub2 > .item > .menu .cb-navigation-text {
  font-weight: bold;
}
.mobile-navigation div.sub3 {
  box-sizing: border-box;
  padding-left: 20px;
}
.mobile-navigation div.sub3 .cb-toggle {
  display: none !important;
}
.mobile-navigation div.sub3 > .item {
  width: 100%;
}
.mobile-navigation div.sub3 > .item.init {
  margin-top: 10px;
}
.mobile-navigation div.sub3 > .item > .menu {
  font-size: 16px;
  line-height: 1.375;
  text-decoration: none;
  padding: 7px 0 7px 16px;
  background-size: 8px 12px;
  background-position: 0 12px;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-black.svg);
  font-weight: bold;
}
.mobile-navigation div.sub3 > .item > .menu.path {
  background-image: url(/images/chevron-right-red.svg);
}
.mobile-navigation div.sub4 {
  box-sizing: border-box;
  padding-left: 16px;
}
.mobile-navigation div.sub4 > .item {
  margin: 2px 0;
}
.mobile-navigation div.sub4 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub4 > .item.exit {
  margin-bottom: 10px;
}
.mobile-navigation div.sub4 > .item > .menu {
  padding-left: 12px;
  background-size: 6px 9px;
  background-position: 0 6px;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-right-black.svg);
  text-decoration: none;
}
.mobile-navigation div.sub4 > .item > .menu.path {
  background-image: url(/images/chevron-right-red.svg);
}
.mobile-navigation div.sub3 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub3 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 36px;
  height: 50px;
  background-size: 12px 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/chevron-bottom-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.content {
  margin-top: 30px;
}
.content--1 .area .pure:not(.fold),
.content--3 .area .unit:not(.fold) {
  padding-bottom: 34px;
}
.content--1 .area .seam:not(.fold),
.content--1 .area .flat:not(.fold),
.content--2 .area .seam:not(.fold),
.content--2 .area .flat:not(.fold) {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .edge:not(.fold),
.content--2 .area .edge:not(.fold) {
  margin-bottom: -14px;
}
.unit.fold + .unit.fold {
  margin-top: -21px;
}
.content--1 .area .fold.pure div.less,
.content--3 .area .fold.pure div.less,
.content--1 .area .fold.seam div.less,
.content--3 .area .fold.seam div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.pure div.more,
.content--3 .area .fold.pure div.more,
.content--1 .area .fold.seam div.more,
.content--3 .area .fold.seam div.more {
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.less,
.content--3 .area .fold.flat div.less,
.content--1 .area .fold.edge div.less,
.content--3 .area .fold.edge div.less {
  padding-top: 8px;
  padding-bottom: 8px;
}
.content--1 .area .fold.flat div.more,
.content--3 .area .fold.flat div.more,
.content--1 .area .fold.edge div.more,
.content--3 .area .fold.edge div.more {
  padding-bottom: 8px;
}
/*# sourceMappingURL=./screen-small.css.map */